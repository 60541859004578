import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Pluie is playing with continuous integration
        </p>
      </header>
    </div>
  );
}

export default App;
